<template>
    <div>
        
        <loading
			:active="isLoading"
			:can-cancel="false"
			:on-cancel="onCancel"
			:is-full-page="true"
		/>

        <b-row>
            <b-col xl="12" md="12" lg="12">
                
                <b-row>
                    <!-- Left Side - Form -->
                    <b-col xl="9" md="9" lg="9">
                        
                        <!-- Product Title -->
                        <b-card no-body>
                            <div class="custom-container">
                                <feather-icon
                                    icon="InfoIcon"
                                    class="info-icon"
                                    v-b-popover.hover.top="
                                        'Item Name'
                                    "
                                />
                                <b-form-input
                                    placeholder="* Product Name"
                                    class="p-2"
                                    v-model="product_details.name"
                                />
                            </div>
                        </b-card>

                        <!-- Product Long Descpriton -->
                        <b-card no-body>
                            <div class="custom-container">
                                <h5 class="p-1 bb-1 ctitle m-0">Long Description</h5>
                                <quill-editor
                                    v-model="product_details.description"
                                />
                            </div>
                        </b-card>

                        <!-- Product Short Descpriton -->
                        <b-card no-body>
                            <div class="custom-container">
                                <h5 class="p-1 bb-1 ctitle m-0">Short Description</h5>
                                <quill-editor
                                    v-model="product_details.short_description"
                                />
                            </div>
                        </b-card>

                        <!-- Product Data -->
                        <b-card no-body>

                            <!-- Top Title -->
                            <div class="product-data-form">
                                <h5 style="align-items: center;">Product Data - </h5> &nbsp;
                                <!-- v-model="product_data_type_selected" -->
                                <b-form-select
                                    v-model="product_details.type"
                                    :options="product_data_options"
                                    style="width: 220px;"
                                />
                            </div>

                            <!-- Vertical Tabs -->
                            <b-tab
                                active
                                title="Left"
                            >
                                <b-tabs
                                    vertical
                                    nav-wrapper-class="nav-vertical"
                                    active
                                >
                                    <!-- General Tab -->
                                    <!-- v-if="product_data_type_selected == 'simple'" -->
                                    <b-tab
                                        v-if="product_details.type == 'simple'"
                                        title="General"
                                    >
                                        <div class="tab-content">
                                            
                                            <b-form-group
                                                label-cols="4"
                                                label-cols-lg="2"
                                                label-size="md"
                                                label="Regular price (₹)"
                                                label-for="input-md"
                                                >
                                                <b-form-input
                                                    id="input-md"
                                                    size="md"
                                                    placeholder="Regular price (₹)"
                                                    style="width: 200px"
                                                    v-model="product_details.regular_price"
                                                />
                                            </b-form-group>

                                            <b-form-group
                                                label-cols="4"
                                                label-cols-lg="2"
                                                label-size="md"
                                                label="Sale price (₹)"
                                                label-for="input-md"
                                                >
                                                <b-form-input
                                                    id="input-md"
                                                    size="md"
                                                    placeholder="Sale price (₹)"
                                                    style="width: 200px"
                                                    v-model="product_details.sale_price"
                                                />
                                            </b-form-group>
                                                
                                        </div>
                                    </b-tab>

                                    <!-- Inventory Tab -->
                                    <b-tab title="Inventory">
                                        <div class="tab-content">
                                            
                                            <b-form-group
                                                label-cols="4"
                                                label-cols-lg="2"
                                                label-size="md"
                                                label="SKU"
                                                label-for="input-md"
                                                >
                                                <b-form-input
                                                    id="input-md"
                                                    size="md"
                                                    placeholder="SKU Value"
                                                    style="width: 200px"
                                                    v-model="product_details.sku"
                                                />
                                                <span class="ml-2">
                                                    <feather-icon
                                                        icon="HelpCircleIcon"
                                                        v-b-popover.hover.top="
                                                            'SKU refers to a Stock-keeping unit, a unique identifier for each distinct product and service that can be purchased.'
                                                        "
                                                    />
                                                </span>
                                            </b-form-group>

                                            <b-form-group
                                                label-cols="4"
                                                label-cols-lg="2"
                                                label-size="md"
                                                label="Manage stock?"
                                                label-for="manage-stock"
                                                >
                                                <b-form-checkbox
                                                    id="manage-stock"
                                                    size="md"
                                                    :value="true"
                                                    name="manage-stock"
                                                    v-model="product_details.manage_stock"
                                                >
                                                    Enable stock management at product level
                                                </b-form-checkbox>
                                            </b-form-group>


                                            <!-- If Stock is Enabled -->
                                            <div v-if="product_details.manage_stock">
                                                <b-form-group
                                                    label-cols="4"
                                                    label-cols-lg="2"
                                                    label-size="md"
                                                    label="Stock quantity"
                                                    label-for="input-sq"
                                                    >
                                                    <b-form-input
                                                        id="input-sq"
                                                        size="md"
                                                        placeholder="Quantity"
                                                        style="width: 200px"
                                                        value="0"
                                                    />
                                                    <span class="ml-2">
                                                        <feather-icon
                                                            icon="HelpCircleIcon"
                                                            v-b-popover.hover.top="
                                                                'Stock quantity. If this is a variable product this value will be used to control stock for all variations, unless you define stock at variation level'
                                                            "
                                                        />
                                                    </span>
                                                </b-form-group>

                                                <b-form-group
                                                    label-cols="4"
                                                    label-cols-lg="2"
                                                    label-size="md"
                                                    label="Allow backorders?"
                                                    label-for="input-allow-backorders"
                                                    >
                                                    <b-form-select
                                                        v-model="allow_backorders_selected"
                                                        :options="allow_backorders"
                                                        style="width: 200px;"
                                                    />
                                                    <span class="ml-2">
                                                        <feather-icon
                                                            icon="HelpCircleIcon"
                                                            v-b-popover.hover.top="
                                                                `If managing stock, this controls whether or not backorders are allowed. If enabled, stock quantity can go below 0.`
                                                            "
                                                        />
                                                    </span>
                                                </b-form-group>

                                            </div>

                                            <b-form-group
                                                v-if="!product_details.manage_stock"
                                                label-cols="4"
                                                label-cols-lg="2"
                                                label-size="md"
                                                label="Stock status"
                                                label-for="input-stock-status"
                                                >
                                                <b-form-select
                                                    v-model="stock_status_selected"
                                                    :options="stock_status"
                                                    style="width: 200px;"
                                                />
                                                <span class="ml-2">
                                                    <feather-icon
                                                        icon="HelpCircleIcon"
                                                        v-b-popover.hover.top="
                                                            `Control whether or not the product is listed as 'in stock' or 'out of stock' on the frontend.`
                                                        "
                                                    />
                                                </span>
                                            </b-form-group>

                                            <b-form-group
                                                label-cols="4"
                                                label-cols-lg="2"
                                                label-size="md"
                                                label="Sold individually"
                                                label-for="sold-individually"
                                                >
                                                <b-form-checkbox
                                                    id="sold-individually"
                                                    size="md"
                                                    value="1"
                                                    name="sold-individually"
                                                >
                                                    Enable this to only allow one of this item to be bought in a single order
                                                </b-form-checkbox>
                                            </b-form-group>


                                        </div>
                                    </b-tab>

                                    <!-- Attributes Tab -->
                                    <b-tab title="Attributes">
                                        <div>
                                            
                                            <div style="display: flex;">
                                                <!-- <b-form-select
                                                    v-model="selected_attribute"
                                                    :options="master_attributes"
                                                    style="width: 200px;"
                                                /> -->
                                                <v-select
                                                    v-model="selected_attribute"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    label="title"
                                                    :options="master_attributes"
                                                    style="width: 200px;"
                                                />
                                                <b-button
                                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                    variant="primary"
                                                    class="ml-1"
                                                    v-on:click="addAttributes"
                                                >
                                                    Add
                                                </b-button>
                                            </div>

                                            <div class="add_attributes">
                                                <div class="mt-1 attribute_section" v-for="single_attr of selected_attributes">
                                                    <h4 class="p-1 bb-1 ctitle">{{single_attr.name}} <b-button class="ml-1" size="sm" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" v-on:click="removeAttribute(single_attr.id)">Remove</b-button></h4>
                                                    <div class="p-1">
                                                        <b-row>
                                                            <b-col xl="4" md="4" lg="4">
                                                                <h5>Name: <b>{{single_attr.name}}</b></h5>

                                                                <b-form-checkbox
                                                                    v-model="single_attr.variation"
                                                                    name="category-checkbox"
                                                                    :value="true"
                                                                >
                                                                    Used for variations
                                                                </b-form-checkbox>

                                                            </b-col>
                                                            <b-col xl="8" md="8" lg="8">
                                                                <h5>Value(s): </h5>
                                                                <v-select
                                                                    v-model="single_attr.selected_value"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="single_attr.values"
                                                                    multiple
                                                                    class="mb-1"
                                                                    style="background-color: #fff;"
                                                                    @input="removeAttribute(-1)"
                                                                />
                                                                <b-button variant="gradient-secondary" size="sm" v-on:click="setAllValues(single_attr.values, single_attr.id)">Select All</b-button>
                                                                <b-button class="ml-1" variant="gradient-secondary" size="sm" v-on:click="removeAllValues(single_attr.id)">Select None</b-button>
                                                                <b-button class="ml-1" variant="gradient-secondary" size="sm" v-on:click="addValueToAttribute(single_attr.id)">Add New Value</b-button>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </b-tab>

                                    <!-- Variation Tab -->
                                    <b-tab
                                        v-if="product_details.type == 'variable'"
                                        title="Variation"
                                    >
                                        <div class="tab-content">
                                            <!-- {{ selected_attributes.length }} -->
                                            <b-button
                                                v-if="selected_attributes.length > 0"
                                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                variant="primary"
                                                class="mb-1"
                                                v-on:click="createVariationForm"
                                            >
                                                Create Variation From Attributes / Re-Generate Form
                                            </b-button>
                                            
                                            <b-alert
                                                v-else
                                                variant="warning"
                                                show
                                            >
                                                <h3 class="alert-heading" >Please add some attributes with value, also check used for Variation.</h3>
                                            </b-alert>

                                            <div class="variation_form">
                                                <b-form
                                                    v-if="variation_form.length > 0"
                                                    ref="form"
                                                    class="repeater-form"
                                                >

                                                    <!-- Row Loop -->
                                                    <b-row
                                                        v-for="(item, index) in variation_form"
                                                        :id="index"
                                                        :key="index"
                                                        ref="row"
                                                    >

                                                        <!-- Title -->
                                                        <b-col md="12" sm="12">
                                                            <b-badge variant="primary">
                                                                <h5 class="color-white text-white mb-0 pb-0">{{ item.attributes[0].option }}</h5>
                                                            </b-badge>
                                                        </b-col>

                                                        <!-- Item Price -->
                                                        <b-col md="3">
                                                            <b-form-group
                                                            label-for="item-price"
                                                            >
                                                            <b-form-input
                                                                id="item-price"
                                                                type="number"
                                                                placeholder="Item Price"
                                                                @input="vrValue($event, 'price', index)"
                                                                :value="item.regular_price ? item.regular_price : ''"
                                                            />
                                                            </b-form-group>
                                                        </b-col>

                                                        <!-- item-qty -->
                                                        <b-col md="2">
                                                            <b-form-group
                                                            label-for="item-qty"
                                                            >
                                                            <b-form-input
                                                                id="item-qty"
                                                                type="number"
                                                                placeholder="Stock Quantity"
                                                                @input="vrValue($event, 'qty', index)"
                                                                :value="item.stock_quantity ? item.stock_quantity : ''"
                                                            />
                                                            </b-form-group>
                                                        </b-col>

                                                        <!-- item-descp -->
                                                        <b-col md="4">
                                                            <b-form-group
                                                            label-for="item-descp"
                                                            >
                                                            <b-form-input
                                                                id="item-descp"
                                                                type="text"
                                                                placeholder="Descprition - Sizes"
                                                                @input="vrValue($event, 'descp', index)"
                                                                :value="item.description ? showJustText(item.description) : ''"
                                                            />
                                                            </b-form-group>
                                                        </b-col>

                                                        <!-- Remove Button -->
                                                        <b-col
                                                            lg="2"
                                                            md="3"
                                                            v-if="form_type != 'create'"
                                                        >
                                                            <b-button
                                                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                                            variant="outline-danger"
                                                            @click="deleteVariation(item.id)"
                                                            >
                                                                <feather-icon
                                                                    icon="XIcon"
                                                                    class="mr-25"
                                                                />
                                                                <span>Delete</span>
                                                            </b-button>
                                                        </b-col>

                                                        <b-col cols="12">
                                                            <hr class="pb-1">
                                                        </b-col>

                                                    </b-row>
                                                    
                                                    <b-button
                                                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                                        variant="outline-success"
                                                        @click="addVariations"
                                                    >
                                                        <feather-icon
                                                            icon="SaveIcon"
                                                            class="mr-25"
                                                        />
                                                        <span>Save Variation</span>
                                                    </b-button>

                                                </b-form>
                                            </div>
                                        </div>
                                    </b-tab>


                                </b-tabs>
                            </b-tab>
                        </b-card>

                        <!-- Product Gallery Image -->
                        <b-card no-body>
                            <h4 class="p-1 bb-1 ctitle">Product Gallery {{ additionalImagesUploaded.length }}</h4>
                            <div class="p-1">
                                
                                <div
                                    class="shadow-none choose-images-card"
                                    v-if="!additionalImagesStatus"
                                >
                                    <a class="fileContainer">
                                        <h5 @click="setImgType('SECONDARY')">
                                            Upload Images
                                        </h5>
                                    </a>
                                </div>

                                <div class="" v-else>
                                    <b-row>
                                        
                                        <!-- ITEM IMAGES -->
                                        <b-col md="6" sm="3" lg="2" xl="2" xs="2" v-for="(addImages, index) in additionalImagesUploaded" :key="addImages.src">
                                            <div
                                                class="shadow-none choose-images-card"
                                            >
                                                <b-img
                                                    rounded
                                                    :src="addImages.src"
                                                />
                                                <span class="delete"
                                                    ><span
                                                        class="remove-icon"
                                                        @click="removeAddImage(index)"
                                                        >Remove</span
                                                    ></span
                                                >
                                            </div>
                                        </b-col>

                                        <!-- ADD MORE -->
                                        <b-col v-if="additionalImagesUploaded.length < 5" md="6" sm="3" lg="2" xl="2" xs="2">
                                            <div
                                                class="shadow-none choose-images-card"
                                            >
                                                <a class="fileContainer">
                                                    <h5 @click="setImgType('SECONDARY')">
                                                        Add More
                                                    </h5>
                                                </a>
                                            </div>
                                        </b-col>

                                    </b-row>
                                </div>
                                
                            </div>
                        </b-card>

                    </b-col>

                    <!-- Right Side - Form -->
                    <b-col xl="3" md="3" lg="3">

                        <!-- Product Save/Draft -->
                        <b-card no-body>
                            <h4 class="p-1 bb-1 ctitle">Publish</h4>
                            <div class="p-1">
                                <b-button-group>
                                    <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        v-on:click="save('draft')"
                                    >
                                        Save Draft
                                    </b-button>

                                    <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        v-on:click="save('publish')"
                                    >
                                        Publish Now
                                    </b-button>
                                </b-button-group>
                            </div>
                        </b-card>

                        <!-- Product Categories -->
                        <b-card no-body>
                            <h4 class="p-1 bb-1 ctitle">Product Categories</h4>
                            <div class="p-1 scroll-in">

                                <b-form-group label="All Categories">
                                    <template v-for="category in product_categories">
                                        <div :key="category.id">
                                        <b-form-checkbox
                                            v-model="product_categories_selected"
                                            :value="category.id"
                                            name="category-checkbox"
                                        >
                                            {{ category.title }}
                                        </b-form-checkbox>
                                        <div v-if="category.child_status">
                                            <div v-for="childCategory in category.children" :key="childCategory.id">
                                            <b-form-checkbox
                                                v-model="product_categories_selected"
                                                :value="childCategory.id"
                                                name="category-checkbox"
                                                class="ml-2"
                                            >
                                                {{ childCategory.title }}
                                            </b-form-checkbox>
                                            </div>
                                        </div>
                                        </div>
                                    </template>
                                </b-form-group>

                            </div>
                        </b-card>

                        <!-- Product Brands -->
                        <b-card no-body>
                            <h4 class="p-1 bb-1 ctitle">Product Brands</h4>
                            <div class="p-1 scroll-in">
                                <b-form-group label="All Brands">
                                    <b-form-checkbox
                                        v-for="option in product_brands"
                                        :key="option.id"
                                        v-model="product_brands_selected"
                                        :value="option.id"
                                        name="flavour-3a"
                                    >
                                        {{ option.title }}
                                    </b-form-checkbox>
                                </b-form-group>
                            </div>
                        </b-card>

                        <!-- IMAGES -->
                        <input v-show="false" type="file" accept="image/*" ref="primaryImage" @change="handlePrimaryImageChange" />
                        <input v-show="false" type="file" accept="image/*" ref="additionalImages" @change="handleAdditionalImagesChange" multiple />

                        <!-- Product Primary Image -->
                        <b-card no-body>
                            <h4 class="p-1 bb-1 ctitle">Product Image</h4>
                            <div class="p-1">
                                
                                <div
                                    class="shadow-none choose-images-card"
                                    v-if="primaryImage == ''"
                                >
                                    <a class="fileContainer">
                                        <h5 @click="setImgType('PRIMARY')">
                                            * Front Image
                                        </h5>
                                    </a>
                                </div>

                                <div
                                    class="shadow-none choose-images-card"
                                    v-if="primaryImage != ''"
                                >
                                    <b-img
                                        :ref="'primaryImage_image'"
                                        rounded
                                        :src="primaryImage"
                                    />
                                    <span class="delete"
                                        ><span
                                            @click="removePrimaryImage()"
                                            class="remove-icon"
                                            >Remove</span
                                        ></span
                                    >
                                </div>
                                
                            </div>
                        </b-card>

                    </b-col>
                </b-row>

            </b-col>
        </b-row>

    </div>
</template>

<script>

import {
	BFormInput,
	BFormGroup,
	BForm,
	BRow,
	BCard,
	BCol,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BCardText,
	BFormTextarea,
	BFormRadio,
	BInputGroup,
	BButtonGroup,
	BInputGroupPrepend,
	BInputGroupAppend,
	BFormDatepicker,
	BImg,
	BFormFile,
	BCollapse,
	VBToggle,
	VBPopover,
	BFormSelect,
	BFormSelectOption,
	BCardBody,
	BAlert,
	BFormCheckbox,
    BTabs, BTab
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import vSelect from "vue-select";
import axios from "axios";
import ProductServices from "@/apiServices/ProductServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'


export default {

    metaInfo: {
		title: "Wordpress - Add Product | Revivify",
		htmlAttrs: {
			lang: "en-US",
		}
	},

    components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
		BCardText,
		BCardBody,
		BCard,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BFormTextarea,
		BFormRadio,
		BButtonGroup,
		BInputGroup,
		BInputGroupPrepend,
		BInputGroupAppend,
		BFormDatepicker,
		BImg,
		BFormFile,
		BCollapse,
		BFormSelect,
		VBPopover,
		VBToggle,
		vSelect,
		BFormSelectOption,
		Loading,
		BAlert,
		BFormCheckbox,
        quillEditor,
        BTabs,
        BTab,
	},

	directives: {
		Ripple,
		"b-toggle": VBToggle,
		"b-popover": VBPopover,
	},

    data() {
        return {
            content: ``,
            isLoading: true,
            product_data_options: [
                { value: 'simple', text: 'Simple Product' },
                { value: 'variable', text: 'Variation Product' }
            ],
            product_data_type_selected: 'simple',
            product_categories: [],
            product_categories_selected: [
                {
                    "id": 1754,
                    "name": "new-in-slider",
                    "slug": "new-in-slider",
                    "parent": 1676,
                    "description": "",
                    "display": "default",
                    "image": null,
                    "menu_order": 0,
                    "count": 6,
                    "_links": {
                        "self": [
                            {
                                "href": "https://www.revivify.com/wp-json/wc/v2/products/categories/1754"
                            }
                        ],
                        "collection": [
                            {
                                "href": "https://www.revivify.com/wp-json/wc/v2/products/categories"
                            }
                        ],
                        "up": [
                            {
                                "href": "https://www.revivify.com/wp-json/wc/v2/products/categories/1676"
                            }
                        ]
                    },
                    "title": "new-in-slider",
                    "child_status": false
                }
            ],
            product_categories_precessing: [],
            product_brands: [],
            product_brands_selected: [],
            stock_status: [
                {
                    id: 1,
                    text: "In Stock",
                    value: "instock"
                },
                {
                    id: 2,
                    text: "Out Of Stock",
                    value: "outofstock"
                },
                {
                    id: 3,
                    text: "On Backorder",
                    value: "onbackorder"
                }
            ],
            stock_status_selected: 'instock',
            allow_backorders: [
                {
                    id: 1,
                    text: "Do not allow",
                    value: "no"
                },
                {
                    id: 2,
                    text: "Allow, but notify customer",
                    value: "notify"
                },
                {
                    id: 3,
                    text: "Allow",
                    value: "yes"
                }
            ],
            allow_backorders_selected: 'no',
            isManageStock: false,
            master_attributes: [],
            selected_attribute: {}, //{value: 1, title: 'Anarkali'},
            selected_attributes: [],
            all_attributes: [],
            product_details: {
                    "id": 0,
                    "name": "",
                    "slug": "",
                    "permalink": "",
                    "date_created": "",
                    "date_created_gmt": "",
                    "date_modified": "",
                    "date_modified_gmt": "",
                    "type": "simple",
                    "status": "draft",
                    "featured": false,
                    "catalog_visibility": "hidden",
                    "description": "",
                    "short_description": "",
                    "sku": "",
                    "price": "0",
                    "regular_price": "0",
                    "sale_price": "",
                    "date_on_sale_from": null,
                    "date_on_sale_from_gmt": null,
                    "date_on_sale_to": null,
                    "date_on_sale_to_gmt": null,
                    "on_sale": false,
                    "purchasable": true,
                    "total_sales": 0,
                    "virtual": false,
                    "downloadable": false,
                    "downloads": [],
                    "download_limit": -1,
                    "download_expiry": -1,
                    "external_url": "",
                    "button_text": "",
                    "tax_status": "taxable",
                    "tax_class": "",
                    "manage_stock": true,
                    "stock_quantity": 1,
                    "backorders": "no",
                    "backorders_allowed": false,
                    "backordered": false,
                    "low_stock_amount": null,
                    "sold_individually": true,
                    "weight": "",
                    "dimensions": {
                        "length": "",
                        "width": "",
                        "height": ""
                    },
                    "shipping_required": true,
                    "shipping_taxable": true,
                    "shipping_class": "",
                    "shipping_class_id": 0,
                    "reviews_allowed": true,
                    "average_rating": "0.00",
                    "rating_count": 0,
                    "upsell_ids": [],
                    "cross_sell_ids": [],
                    "parent_id": 0,
                    "purchase_note": "",
                    "categories": [],
                    "tags": [],
                    "images": [],
                    "attributes": [],
                    "default_attributes": [],
                    "variations": [],
                    "grouped_products": [],
                    "menu_order": 0,
                    "price_html": "",
                    "related_ids": [],
                    "meta_data": [],
                    "stock_status": "instock",
                    "has_options": false
                },

            form_type: 'create',
            primaryImage: '',
            additionalImages: [],
            additionalImagesUploaded: [],
            additionalImagesStatus: false,
            s3_base_url: 'https://aws-bucket-ajay.s3.ap-south-1.amazonaws.com/',

            create: [],
            variation_form: []

        }
	},

    beforeMount() {
        //alert(this.$route.query.id);
        this.loadAllCategories();
        this.loadAllBrands();
        this.loadAllAttributes();
        if(this.$route.query.id){
            this.loadProductDetails(this.$route.query.id);
        }
    },

    /* watch: {
        product_categories_precessing: {
            handler: function() {
                this.processProductDetails(this.product_details);
            }
        }
    }, */

    methods: {

        removePrimaryImage(){
            this.primaryImage = '';
        },

        setImgType(type) {
            if(type == 'PRIMARY'){
                this.$refs["primaryImage"].click();
            }else{
                this.$refs["additionalImages"].click();
            }
		},

        async handlePrimaryImageChange(event) {
            this.primaryImage = event.target.files[0];

            const formData = new FormData();
            formData.append('primaryImage', this.primaryImage);

            try {

                const response = await ProductServices.saveAdminWPUploadImageToS3(formData);

                if (response.status) {
                    //this.primaryImage = this.s3_base_url + response.data.data;
                    this.primaryImage = response.data.data;
                    console.log('Images uploaded successfully.', this.primaryImage);
                } else {
                    this.primaryImage = '';
                    console.error('Failed to upload images.');
                }
            } catch (error) {
                console.error('Error:', error);
            }
            
        },

        async handleAdditionalImagesChange(event) {

            let maxImages = 5; 
            this.additionalImages = event.target.files;
            console.log("handleAdditionalImagesChange ",this.additionalImages.length);
            const selectedFiles = this.additionalImages;
            maxImages = (parseInt(maxImages) - parseInt(this.additionalImagesUploaded.length, 10));

            if (selectedFiles.length > maxImages) {
                // Reset the input field and show an error message
                this.additionalImages = [];
                alert(`You can only upload up to ${maxImages} images.`);
                return;
                return false;
            }

            const formData = new FormData();
            for (let i = 0; i < this.additionalImages.length; i++) {
                formData.append('additionalImages', this.additionalImages[i]);
            }

            try {

                const response = await ProductServices.saveAdminWPUploadImageToS3Multi(formData);

                if (response.status) {
                    //this.primaryImage = this.s3_base_url + response.data.data;
                    //this.additionalImagesUploaded = response.data.data;
                    response.data.data.map((data, index) => {
                        const obj = {
                            src: data
                        }
                        this.additionalImagesUploaded.push(obj);
                    })
                    this.additionalImagesStatus = true;
                    console.log('Images uploaded successfully.', response.data.data);
                } else {
                    this.primaryImage = '';
                    console.error('Failed to upload images.');
                }
            } catch (error) {
                console.error('Error:', error);
            }

        },

        removeAddImage(index) {
            //const images = this.additionalImagesUploaded.length;
            if (this.additionalImagesUploaded.length > 0) { // only splice array when item is found
                this.additionalImagesUploaded.splice(index, 1);
            }
        },

        addAttributes(){
            this.isLoading = true;
            const getSID = this.selected_attribute.value;
            const idExists = this.selected_attributes.some(item => item.id === getSID);

            if(!idExists){
                
                ProductServices.getAdminWPAttributeDetails(getSID).then((response) => {
                    let allAttrValuesResp = response.data.data;
                    /* allAttrValuesResp.map((data, index) => {
                        data.value = data.id;
                        data.title = data.name;
                    }) */
                    const newObj = {
                        id: getSID,
                        name: this.selected_attribute.title,
                        values: allAttrValuesResp,
                        selected_value: [],
                        variation: false, 
                        visible : true
                    }
                    this.selected_attributes.push(newObj);
                    this.isLoading = false;
                    //console.log(this.selected_attributes);
                }).catch((error) => {
                    this.isLoading = false;
                    this.errorMessage = error.message;
                    console.error("There was an error in getAdminWPProducts!", error);
                });

                
            }else{
                this.isLoading = false;
                alert("Already Added");
            }
        },

        removeAttribute(sid){
            const updatedItems = this.selected_attributes.filter(item => item.id !== sid);
            this.selected_attributes = updatedItems;
        },

        setAllValues(values, id){
            
            let getSelectedAttr = this.selected_attributes;
            console.log("setAllValues", {id, values, getSelectedAttr});
            getSelectedAttr.map((data, index) => {
                if(data.id === id){
                    this.selected_attributes[index].selected_value = values;
                }
            });
            this.removeAttribute(-1);
        },

        removeAllValues(id){
            let getSelectedAttr = this.selected_attributes;
            getSelectedAttr.map((data, index) => {
                if(data.id === id){
                    this.selected_attributes[index].selected_value = [];
                }
            });
            this.removeAttribute(-1);
        },

        addValueToAttribute(id){
            const getNewValue = prompt('Are you sure you want to save this thing into the database?');
            if (getNewValue != null) {
                // Save it!
                //console.log('Thing was saved to the database.', {id, getNewValue});

                const getSelected = this.selected_attributes;

                /* this.selected_attributes.forEach((data, index) => {
                    if (data.id == id) {
                        data.values = [...data.values, getNewValue];
                    }
                }); */

                this.isLoading = true;
                ProductServices.addAttrValues({attr_id: id, name: getNewValue})
                .then((response) => {
                    //let allAttrResp = response.data.data;
                    //const getSelected = this.selected_attributes;

                    let allAttrValuesResp = response.data.data;
                    this.selected_attributes.forEach((data, index) => {
                        if (data.id == id) {
                            data.values = allAttrValuesResp; //[...data.values, getNewValue];
                        }
                    });
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.error("There was an error in addValueToAttribute!", error);
                });
                
            } else {
                // Do nothing!
                console.log('Thing was not saved to the database.', {id, getNewValue});
            }
        },

        loadAllAttributes() {
            this.isLoading = true;
            ProductServices.getAdminWPAllMasterAttributes()
            .then((response) => {
                let allAttrResp = response.data.data;
                allAttrResp.map((data, index) => {
                    data.value = data.id;
                    data.title = data.name;
                })
                this.master_attributes = allAttrResp;
                this.selected_attribute = allAttrResp[0];
                this.isLoading = false;
            })
            .catch((error) => {
                this.isLoading = false;
                this.errorMessage = error.message;
                console.error("There was an error in getAdminWPProducts!", error);
            });
        },

        loadAllCategories() {
            this.isLoading = true;
            ProductServices.getAdminWPAllCategories()
            .then((response) => {
                let allCats = response.data.data.display;
                let allProcessCats = response.data.data.processing;
                this.product_categories = allCats;
                this.product_categories_precessing = allProcessCats;
                
                if(this.$route.query.id){
                    this.processProductDetails(this.product_details);
                }
                console.log("response.data.data", response.data.data);
                this.isLoading = false;
            })
            .catch((error) => {
                this.isLoading = false;
                this.errorMessage = error.message;
                console.error("There was an error in getAdminWPAllCategories!", error);
            });
        },

        loadAllBrands() {
            this.isLoading = true;
            ProductServices.getAdminWPAllBrands()
            .then((response) => {
                let allBrands = response.data.data;
                this.product_brands = allBrands;
                this.isLoading = false;
            })
            .catch((error) => {
                this.isLoading = false;
                this.errorMessage = error.message;
                console.error("There was an error in getAdminWPAllBrands!", error);
            });
        },

        loadProductDetails(pid){
            if(pid != undefined || parseInt(pid) < 0 || pid != ''){
                this.isLoading = true;
                ProductServices.getAdminWPProductDetails(pid)
                .then((response) => {
                    let resp = response.data.data.product_details;
                    let av = response.data.data.attributes_values;
                    let vv = response.data.data.variations;
                    this.variation_form = response.data.data.variations;
                    
                    this.product_details = resp;
                    //this.processProductDetails(resp);
                    if(response.data.status){ //&& resp.attributes.length > 0
                        resp.attributes.map((atrData, atrindex) => {
                            atrData.selected_value = atrData.options;
                            const objWithDesiredId = av.find(obj => obj.id === atrData.id);
                            atrData.values = objWithDesiredId['options'];
                        });
                        resp.images.map((imgData, imgIndex) => {
                            if(resp.images.length > 1 && imgIndex == 0){
                                this.primaryImage = imgData?.src;
                            }else{
                                const obj = {
                                    src: imgData?.src
                                }
                                this.additionalImagesUploaded.push(obj);
                            }
                        })

                        if(resp.images.length > 0){
                            this.additionalImagesStatus = true;
                        }

                        this.selected_attributes = resp.attributes;
                        this.form_type = 'update';
                    }else{
                        this.form_type = 'create';
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.errorMessage = error.message;
                    console.error("There was an error in getAdminWPAllCategories!", error);
                });
            }
        },

        save(type){
            this.isLoading = true;
            
            console.log("product_details", this.product_details);
            console.log("selected_attributes", this.selected_attributes);

            if(this.product_details.name == "" || this.product_details.name == null){
                alert("Product Name is Required");
                return false;
                return;
            }

            let finalAttr = this.selected_attributes;
            if(finalAttr.length > 0){
                finalAttr.map((data, index) => {
                    data.options = data.selected_value;
                    data.position = index;
                    delete data.selected_value;
                    delete data.values;
                })
            }

            let sendData = this.product_details;
            sendData.attributes = finalAttr;
            sendData.status = type;

            let finalImages = [];
            if(this.primaryImage !== ''){
                finalImages.push({src: this.primaryImage});
            }
            if(this.additionalImagesUploaded.length > 0){
                this.additionalImagesUploaded.map((data, index) => {
                    finalImages.push({src: data.src});
                })
            }
            sendData.images = finalImages;

            if(this.form_type === 'update'){
                ProductServices.saveAdminWPProductUpdate(sendData)
                .then((response) => {
                    console.log("response", response);
                }).catch((error) => {
                    console.log("error", error);
                })
            }else{
                delete sendData.id;
                ProductServices.saveAdminWPProductCreate(sendData)
                .then((response) => {
                    console.log("response", response);
                }).catch((error) => {
                    console.log("error", error);
                })
            }

            this.isLoading = false;
        },

        processProductDetails(data){

            // calculate selected_category
            let getCategories = data.categories;
            if(getCategories.length > 0){

                /* this.product_categories_precessing.map((proCatData, proCatIndex) => {
                    getCategories.map((gcData, gcIndex) => {
                        gcData.title = gcData.name;
                    });
                }) */

                console.log("getCategories", getCategories);

                const gf = [];
                getCategories.map((gcData, gcIndex) => {
                    const getFiltered = this.product_categories_precessing.filter(item => item.id === gcData.id);
                    console.log("getFiltered", getFiltered);
                    if(getFiltered){
                        //this.product_categories_selected.push(getFiltered);
                        gf.push(getFiltered);
                    }
                });
                //this.product_categories_selected = gf;
            }
            this.isLoading = false;
        },

        vrValue(value, type, index){
            let vf = this.variation_form;
            if(type==="price"){
                vf[index].regular_price = value;
            }else if(type==="qty"){
                vf[index].variable_stock = value;
                vf[index].stock_status = "instock";
                vf[index].manage_stock = true;
                vf[index].stock_quantity = value;
            }else if(type==="descp"){
                vf[index].description = value;
            }
            console.log("vrValue", vf);
            this.variation_form = vf;
        },

        createVariationForm(){

            let variationArrts = this.selected_attributes.filter(item => item.variation === true);
            let VA = [];
            variationArrts.map((dd, ii) => {
                if(dd.variation){
                    dd.selected_value.map((childData, childIndex) => {
                        const newObj = {
                            id: dd.id,
                            visible: true,
                            variation: true,
                            option: childData,
                        }
                        VA.push(newObj);
                    })
                }
            })
            //console.log("addVariations", VA);

            let newFinalVariation = [];
            VA.map((fd, fi) => {
                const oobbjj = {
                    regular_price: "1.00",
                    attributes: [fd]
                }
                newFinalVariation.push(oobbjj);
            });

            if(newFinalVariation.length <= 0){
                alert("Please add attributes with value and please tick (check) [Used for variations]");
                return;
            }

            this.variation_form = newFinalVariation;
            const createObj ={create: this.variation_form}
            console.log("createVariationForm", createObj);

            //this.addVariations(createObj);

        },

        addVariations() {

            /* let variationArrts = this.selected_attributes.filter(item => item.variation === true);
            let VA = [];
            variationArrts.map((dd, ii) => {
                if(dd.variation){
                    dd.selected_value.map((childData, childIndex) => {
                        const newObj = {
                            id: dd.id,
                            visible: true,
                            variation: true,
                            option: childData,
                        }
                        VA.push(newObj);
                    })
                }
            })
            console.log("addVariations", VA);

            let newFinalVariation = [];
            VA.map((fd, fi) => {
                const oobbjj = {
                    regular_price: "1.00",
                    attributes: [fd]
                }
                newFinalVariation.push(oobbjj);
            });

            if(newFinalVariation.length <= 0){
                alert("Please add attributes with value and please tick (check) [Used for variations]");
                return;
            }

            const createObj ={
                create: newFinalVariation
            } */

            

            const createObj = {create: this.variation_form}
            //console.log("createVariationForm", createObj);
            //return;
            
            console.log("product_details", this.product_details);
            console.log("selected_attributes", this.selected_attributes);

            if(this.product_details.name == "" || this.product_details.name == null){
                alert("Product Name is Required");
                this.isLoading = false;
                return false;
                return;
            }

            let finalAttr = this.selected_attributes;
            if(finalAttr.length > 0){
                finalAttr.map((data, index) => {
                    data.options = data.selected_value;
                    data.position = index;
                    //delete data.selected_value;
                    //delete data.values;
                })
            }

            let sendData = this.product_details;
            sendData.attributes = finalAttr;
            if(sendData.status === "publish"){
                sendData.status = "publish";
            }else{
                sendData.status = "draft";
            }
            

            let finalImages = [];
            if(this.primaryImage !== ''){
                finalImages.push({src: this.primaryImage});
            }
            if(this.additionalImagesUploaded.length > 0){
                this.additionalImagesUploaded.map((data, index) => {
                    finalImages.push({src: data.src});
                })
            }
            sendData.images = finalImages;

            this.isLoading = true;
            
            if(this.form_type === 'update'){
                //update
                let newFilteredVR = [];
                this.variation_form.map((dd, ii) => {
                    newFilteredVR.push({
                        id: dd.id,
                        description: dd.description,
                        regular_price: dd.regular_price,
                        stock_quantity: dd.stock_quantity
                    })
                })
                const updateObj = {update: newFilteredVR}
                const payload = {
                    product: sendData,
                    variations: updateObj
                }
                //console.log("payload", payload);
                ProductServices.saveAdminWPProductVariationCreate(payload)
                .then((response) => {
                    console.log("response", response);
                }).catch((error) => {
                    console.log("error", error);
                })
            }else{
                delete sendData.id;
                const payload = {
                    product: sendData,
                    variations: createObj
                }
                ProductServices.saveAdminWPProductVariationCreate(payload)
                .then((response) => {
                    console.log("response", response.data.data.parent.id);
                    ///wp-add-product?id=109813
                    const pid = response.data.data.parent.id;
                    this.isLoading = false;
                    if(pid && parseInt(pid) > 0){
                        router.push({ path: '/wp-add-product', query: { id: pid } }) //110400
                        //router.push({ path: '/wp-add-product', query: { id: '110400' } }) //110400
                        this.loadProductDetails(pid);
                    }
                }).catch((error) => {
                    console.log("error", error);
                })
            }
            this.isLoading = false;
        },

        deleteVariation(vid){
            this.isLoading = true;
            const deleteArr = { delete: [vid] };
            const payload = { 
                product: {id: this.product_details.id},
                variations:  deleteArr
            };
            ProductServices.saveAdminWPProductVariationCreate(payload)
            .then((response) => {
                console.log("response", response);
                this.loadProductDetails(this.$route.query.id);
            }).catch((error) => {
                console.log("error", error);
            })
            this.isLoading = false;
        },

        showJustText(data){
            return data.replace(/<\/?[^>]+(>|$)/g, '').replace(/\\n/g, '');
        },

        onCancel() {
            console.log("Seller cancelled the loader.");
            this.isLoading = false;
        },
    }
}
</script>

<style>
.custom-container {
	position: relative;
}

.info-icon {
	position: absolute;
	top: 0;
	right: 0;
	top: 50%;
	margin-right: 10px;
	transform: translateY(-50%);
	z-index: 1;
}

.info-icon-pd {
	position: absolute;
	top: 0;
	right: 0;
	top: 50%;
	margin-right: 10px;
	transform: translateY(-50%);
	z-index: 1;
}

.product-data-form{
    display: flex;
    padding: 15px;
    border-bottom: 2px solid #a96a366b;
    align-items: center;
}

.ctitle {
    font-weight: 700;
    color: black;
}

.bb-1{
    border-bottom: 1px solid #a96a366b;
}

.bb-2{
    border-bottom: 2px solid #a96a366b;
}

.tab-content{
    padding: 8px;
}

.tabs ul {
    
    margin-bottom: 0px;
    border-radius: 0rem;
}

div.tabs div.nav-vertical {
    border-right: 2px solid #a96a366b;
    padding-right: 0px;
    margin-right: 2px;
}

.tab-content .col {
    align-self: center;
    display: flex;
}

.tab-content .col span {
    align-self: center;
}

.attribute_section {
    border: 1px solid #a96a366b; width: 97%;
    border-radius: 5px;
    background-color: #f5f5f5af;
}

.scroll-in {
    height: 300px;
    overflow-y: scroll;
    padding-bottom: 5px;
}



.choose-images-card {
	border: #ccc 1px dashed;
	text-align: center;
	color: #666;
	transition: all 0.3s ease;
	height: 210px;
	width: 100%;
	position: relative;
	border-radius: 8px !important;
	background-color: #f8f8f8 !important;
	margin-bottom: 15px !important;
}

.choose-images-card h5 {
	display: flex;
	font-size: 12px;
	color: #adadad;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100%;
	justify-content: center;
}

.choose-images-card input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	margin: 0;
	opacity: 0;
	/* -ms-filter: 'alpha(opacity=0)'; */
	font-size: 200px !important;
	direction: ltr;
	cursor: pointer;
}

.choose-images-card img {
	width: 100%;
	margin: 0px;
	height: 100%;
	padding: 2px;
	/* border-radius: 12px !important; */
}

.choose-images-card span.delete {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	align-items: center;
	align-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
	justify-content: center;
}

.choose-images-card .card-body {
	padding: 0px !important;
}



.choose-images-card span.delete {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	align-items: center;
	align-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
	justify-content: center;
}

.choose-images-card .card-body {
	padding: 0px !important;
}

span.delete .remove-icon {
	background: rgb(238, 238, 238, 0.75);
	padding: 20px;
	cursor: pointer;
    border-radius: 5px;
    color: black;
    font-weight: 700;
}

.prod-image {
	width: 100% !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>